<!-- add by Liwh time:2024-09-01 description:轮播图编辑页面 -->

<template>
  <el-container class="Wrapper">
    <el-container class="containerse">
      <el-main>
        <div class="addition">
          <el-form :label-position="label - postion">
            <p>
              <!-- radio 1.文章 2.商品 -->
              <span class="name"><i style="color: red;">*</i>轮播图:</span>
              <span class="avatar1">
                <el-upload v-model:file-list="fileList" action="https://www.jingtengyiliao.com/api/announcement/upload"
                  name="file" limit="1" list-type="picture-card" :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove" :on-success="handleSuccess">
                  <el-icon>
                    +
                  </el-icon>
                </el-upload>
                <el-dialog v-model="dialogVisible">
                  <img w-full :src="dialogImageUrl" alt="Preview Image" />
                </el-dialog>
              </span>
              <!-- <el-upload class="avatar-uploader" action="" :http-request="upload_1" :show-file-list="false"
                  list-type="picture-card">
                  <img :key="image1" v-if="image1 != '' && image1 != undefined" :src="image1" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon">+</i>
                </el-upload>
                <img v-if="image1 != '' && image1 != undefined" @click="deletes1" class="delete"
                  src="../assets/delete.png">
              </span> -->
            </p>

            <p>
              <span class="name"><i style="color: red;">*</i>关联内容：</span>
              <span class="inputs">
                <el-select v-model="valuese" placeholder="请选择要关联的内容" @change="setTypeNumber" prop="typeNumber">
                  <el-option v-for="item in managementese" :key="item.value" :label="item.label" :value="item.value" />
                </el-select></span>
            </p>
            <p v-if="typeNumber == '1'">
              <span class="name"><i style="color: red;">*</i>文章标题:</span>
              <span class="input"> <el-input v-model="headline" placeholder="请输入内容" type="textarea" rows="1" /></span>
            </p>
            <p v-if="typeNumber == '1'">
              <span class="name"><i style="color: red;">*</i>文章内容:</span>
              <span class="input"> <el-input v-model="contentArticle" placeholder="请输入内容" type="textarea"
                  rows="15" /></span>
            </p>

            <p v-if="typeNumber == '2'">
              <span class="name"><i style="color: red;">*</i>商品信息:</span>
              <span class="input"> <el-input v-model="productNames" placeholder="请选择关联的商品" readonly
                  @click="openProductDialog" /></span>
              <span class="input" style="display: none"> <el-input v-model="productIds" maxlength="100"
                  placeholder="请选择关联的商品" /></span>
            </p>

            <p class="button">
              <el-button @click="save" type="primary">保存</el-button>
              <el-button @click="concel" type="primary">返回</el-button>
            </p>
            <p></p>
          </el-form>

        </div>
      </el-main>
    </el-container>
  </el-container>

  <chooseRelativeProductDialog v-model:modelValue="isReasonDialogVisible" @confirm="setRelativeProduct" />

</template>

<script setup>
import { useRoute, useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import instance from '../network/storester.js'
import chooseRelativeProductDialog from './ChooseRelativeProductDialog'
import { ref, toRaw } from 'vue'


const router = useRouter()
const route = useRoute()
const contentArticle = ref('');
const typeNumber = ref('');
const productNames = ref('');
const productIds = ref('');
const isReasonDialogVisible = ref(false);
const image1 = ref('');
const img1 = ref('');
const valuese = ref('')
const headline = ref('')
const storeId = ref('')
const fileList = ref([])

const handleSuccess = (response, file, files) => {

  // 此处的response就是服务器响应的对象
  img1.value = response.data;
}

const deletes1 = () => {
  image1.value = "";
  img1.value = "";
}

const setTypeNumber = (value) => {
  typeNumber.value = value;

}

const openProductDialog = () => {
  isReasonDialogVisible.value = true
}

const setRelativeProduct = (value) => {
  console.log(value)
  productIds.value = value.id;
  productNames.value = value.name;
  storeId.value = parseInt(value.storeId)
  console.log(storeId.value)
}
if (route.query.img1 != null && route.query.img1 != '' && route.query.img1 != undefined) {
  fileList.value = [
    {
      name: 1,
      url: `https://www.jingtengyiliao.com/api/common/download?name=${route.query.img1}`
    }
  ]
}

img1.value = route.query.img1

contentArticle.value = route.query.contentArticle;
headline.value = route.query.headline;
//判断该视频的分类
valuese.value = route.query.typeNumber
typeNumber.value = route.query.typeNumber
productIds.value = route.query.productIds
storeId.value = route.query.storeId
productNames.value = route.query.productNames
const dialogImageUrl = ref('')
const dialogVisible = ref(false)
const handlePictureCardPreview = (uploadFile) => {
  console.log(uploadFile.url);
  dialogImageUrl.value = uploadFile.url
  dialogVisible.value = true
}
const handleRemove = (uploadFile, uploadFiles) => {

  instance.delete("/api/upload/remove?url=" + img1.value)
    .then((response) => {
      if (response.data.state == 20000) {
        ElMessage.success("删除文件完成！")
      }
    })

}
const concel = () => {
  router.push('./statimg')
}

const managementese = [
  {
    value: '1',
    label: '文章',
  },
  {
    value: '2',
    label: '商品',
  }
]

//保存信息
const save = () => {

  ElMessageBox.confirm(
    '确定保存?',
    '提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(() => {
      console.log('进入')
      //轮播图关联文章内容，校验文章内容是否为空
      if (valuese.value == "1" && contentArticle.value === '' && headline.value === '') {
        ElMessage({
          type: 'error',
          message: '文章内容不能为空',
        })
        return;
      }
      console.log('进入')
      //判断轮播图是否为空
      if (img1.value === '') {
        ElMessage({
          type: 'error',
          message: '轮播图为空',
        })
        return;
      }
      console.log('进入')
      const values = Number(valuese.value);
      const id = Number(route.query.id);
      const productId = String(productIds.value);
      console.log('进入')
      console.log(route.query.id)
        //存储api需要信息
        const ade = {
          "carousel": {
            "id": id,
            "storeId": storeId.value,
            "contentArticle": contentArticle.value,
            //"img1": image1.value,
            "headline": headline.value,
            "img1": img1.value,
            "typeNumber": values,
            // "productNames": productNames.value,
          },
          "relativeProductIdList": productIds.value ? String(productIds.value).split(",") : []
        }
        //保存轮播图信息
        instance.post(`api/carousels/saveCarouseInfo`, ade)
          .then(response => {
            if (response.data.code == 0) {
              ElMessage({
                type: 'error',
                message: `${response.data.msg}`,
              })
              return;
            }
            ElMessage({
              type: 'success',
              message: '保存成功',
            })
            router.push('./statimg')
          }).catch(() => {
            ElMessage({
              type: 'error',
              message: '保存失败',
            })
          })
        
    })

    .catch (() => {
  ElMessage({
    type: 'info',
    message: '取消操作',
  })
})
}




</script>

<style>
.addvideo {
  /* display: flex; */
  width: 100vw;
  height: 100vh;
  margin-top: 10vh;
}

.addvideo span {
  font-size: 1rem;
}

.addvideo .bus {
  /* padding-left: 1vw; */
  margin-left: 1vw;
}

.addvideo .el-upload video {
  width: 20vw;
  height: 10vw;
}

.addvideo .el-select {
  width: 20vw;
}

.avatar1 {
  position: relative;
}

.containerse {
  position: fixed;
  top: 10vh;
  left: 15vw;
}



.addition {
  width: 100vw;
  height: 80vh;
}

.addition .el-input {
  width: 25vw;
}

.addition p {
  padding-top: 5vh;
  display: flex;
}

.addition .el-textarea {
  width: 25vw;
}

.name {
  width: 9vw;
}

.addition .input {
  display: flex;
}

.addition .button {
  display: flex;
  margin-left: 12vw;
}
</style>